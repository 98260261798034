.calendar-container .fc-widget-header tr {
    flex-direction: row;
    display: flex;
    /* padding-bottom: 10px; */
}
.calendar-container .fc-widget-header thead tr td {
    border-style: none;
    border-width: 1px;
    vertical-align: top;
}
.demo-app-calendar .fc-widget-header tr :first-child {
    border-top-left-radius: 8%;
}
.demo-app-calendar .fc-widget-header tr :last-child {
    border-top-right-radius: 8%;
}
.fc-widget-header {
    border: none !important;
}
.fc-widget-header table {
    margin-bottom: 10px;
}
.fc table {
    border-collapse: separate;
    border-spacing: 1px;
}
/* .fc-widget-content {
    border-style: none;
  } */

.demo-app-calendar {
    width: 100%;
    height: 100%;
    min-width: 1024px;
    .fc-day-header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #111d5e;
        color: white;
        height: 3rem;
        width: 100%;
        border-style: none;
        border-width: 1px;
        vertical-align: top;
    }
}

/* .fc-widget-content:not(.fc-row) td{
    border: none !important;
  } */
.fc-body > tr > td {
    border: none;
}
.fc-col-header {
    th {
        border: none;
    }
}
.fc-scrollgrid-section-header > td {
    background-color: #111d5e;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.fc-scroller-harness {
    height: 30px;
    display: flex;
    align-items: center;
}
.fc {
    // .fc-daygrid-day-frame {
    //     height: 120px;
    //     width: 120px;
    // }
    .fc-daygrid-day-top {
        flex-direction: row;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.fc-daygrid {
    .fc-scrollgrid {
        border: none;
    }
    .fc-scrollgrid-section > td {
        border: none;
    }
}
.fc-col-header-cell-cushion {
    color: white;
}

.fc-scrollgrid-sync-table {
    height: 100% !important;
    width: 100% !important;
    td {
        max-width: 120px !important;
        max-height: 120px !important;
    }
    .fc-day-other {
        background-color: #ececee !important;
    }
}

.fc-daygrid-day-top {
    a {
        color: #7f828e;
        font-size: 1.14rem;
        font-weight: 500;
    }
}
// .fc-daygrid-day{
//     background-color: #ECECEE;
// }
.bgColor {
    background-color: #a2cfff !important;
}
.fc-daygrid-event {
    pointer-events: none;
    font-weight: 500;
    font-size: 1rem;
}
// .fc {
//     .fc-day-today {
//         background-color: #ececee !important;
//     }
// }
.fc-daygrid-body {
    height: 100%;
    width: 100%;
}

// PopupDetail

@media (max-width: 992px) {
    .W29F2100-total {
        float: left !important;
    }
    .div-prev-next {
        height: 50px !important;
        display: block !important;
        padding-top: 0px !important;
    }
}
@media (max-width: 720px) {
    .W29F2100-detail-container {
        width: 100% !important;
    }
}
.W29F2100-total {
    float: right;
}
.div-prev-next {
    padding-top: 10px;
    height: 110px;
    display: flex;
    align-items: center;
}
